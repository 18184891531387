.invoice {
    padding: 2rem;
    width: 100%;
    height: 100vh;
    max-width: 900px;
    margin: 0 auto;
    p {
        margin-bottom: 0;
    }
    .address p {
        line-height: 1.3;
    }
    h4 {
        margin-bottom: 0;
    }
    th {
        font-weight: bolder;
        text-transform: uppercase;
        padding-top: 50px;
    }
    .items-section tr:last-of-type td {
        padding-bottom: 50px;
    }
    .items-section td:first-child, th:first-child {
        padding-left: 60px;
    }
    .items-section td:last-child, th:last-child {
        padding-right: 60px;
    }
    .item-wrapper {
        margin: 0 -60px;
    }
}

.logo-section img {
    max-width: 150px;
}

.business-info, .customer-details, .items-section, .total-section, .conversion-section {
    margin-bottom: 2rem;
}

.business-info h3 {
    margin: 0;
    padding: 0;
    font-size: 1.4rem;
    font-weight: 600;
}

.customer-details h4, .items-section h4, .payment-details h4 {
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    border-top: 2px solid #41505f;
    border-bottom: 2px solid #41505f;
    background-color: #41505f;
    padding: 10px 60px;
    margin: 0 -60px 0 -60px;
}

.items-section ul {
    list-style-type: none;
    padding: 0;
}

.dashed-seperator {
    border-top: dashed 1px #e3e5ec;
    margin-top: 10px;
    padding-top: 10px;
}

.items-section ul li {
    padding: 0.5rem 0;
    border-bottom: 1px solid #eee;
}

.total-section, .conversion-section {
    font-size: 1.2rem;
    font-weight: bold;
}

.items-section {
    margin-top: 2rem;
}

.items-section Table {
    width: 100%;
}

.invoice-logo {
    max-width: 175px;
}

[data-skin="dark"] {
    .invoice-logo {
        filter: invert(1);
    }
}

.payment-details {
    h4 {
        margin-bottom: 20px;
    }
    p {
        font-size: 0.85em;
    }
}